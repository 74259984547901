.action{
    display: flex;
    gap: 1em;
}
.content-container{
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.addBtn{
    margin-left: 100%;
    margin-bottom: 3%;
    width: 8%;
    display: flex;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background-color: #1A56DB;
}

.addBtn:hover {
    background-color: #233876;
}
.text-xs{
    background-color: #8739ef;
}