.sidebar {
    width: 25%;
    display: flex;
    gap: 1rem;
}

.sidebar-container {
    background-color: #FFFFFF;
    min-height: 100vh;
    color: #f3f4f6;
    transition: width 500ms;
}

.sidebar-container.open {
    width: 17rem;
}

.sidebar-container.closed {
    width: 4rem;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.logo-button {
    width: 100%;
    display: flex;
    gap: 1rem;
    font-size: 1.125rem;
    font-weight: 700;
    padding-top: 14px;
    padding-right: 24px;
    padding-bottom: 14px;
    padding-left: 24px;
    border-radius: 0.75rem;
    box-shadow: 0px 20px 50px 0px #3745571A;
}

.logo-button.active {
    width: 75%;
    height: 6.5vh;
    margin-top: 5%;
    background-color: #C698FF;
}

.logo-text {
    font-family: Poppins, serif;
    font-size: 15px;
    font-weight: 500;
}

.logo-text.hidden {
    display: none;
}

.menu-container {
    margin-top: 12%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-left: 18%;
}

.menu-item {
    width: 50%;
    display: flex;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.5rem;
    border-radius: 0.375rem;
    color: #737791;
}

.menu-item:hover {
    background-color: #f3f4f6;
}

.icon-container {
    min-width: 20px;
    display: flex;
    justify-content: center;
}

.menu-text {
    font-family: Poppins, serif;
    flex: 1;
    padding-left: 20%;
    white-space: pre;
}

.menu-text.hidden {
    display: none;
}
.head{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 13%;
    margin-top: 20%;
}
.titre{
    color: #151D48;
    font-family: Poppins, serif;
    font-size: 24px;
    font-weight: 600;
}
.head-img{
    width: 45px;
}
.sidebar {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.sidebar-container {
    background-color: #FFFFFF;
    min-height: 100vh;
    color: #f3f4f6;
    transition: width 500ms;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.sidebar-container.open {
    width: 17rem;
}

.sidebar-container.closed {
    width: 0;
    overflow: hidden;
}

.menu-toggle-button {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 1100;
    background: #C698FF;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
    .menu-toggle-button {
        display: flex;
    }
    
    .sidebar-container {
        width: 0;
    }
    
    .sidebar-container.open {
        width: 14rem;
    }
}

@media screen and (max-width: 480px) {
    .menu-toggle-button {
        margin-left: 100%;
        display: flex;
        top: 0.5rem;
        left: 0.5rem;
    }
    
    .sidebar-container {
        width: 0;
    }
    
    .sidebar-container.open {
        margin-left: 100%;
        width: 12rem;
    }
}