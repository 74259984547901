.content{
    display: none;
}
.select-toggle{
    background-color: #1f2937;
}
.cotent-container{
    margin-left: 15%;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

.dashboard {
    display: flex;
    height: 100vh;
    background-color: #f5f5f5;
}

.sidebar-open .main-content {
    margin-left: 250px;
}

.min-content {
    flex: 1;
    transition: margin-left 0.3s ease;
    background-color: #ffffff;
    padding: 1rem;
    min-height: 100vh;
}

@media (min-width: 1024px) {
    .dashboard {
        flex-direction: row;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .dashboard {
        flex-direction: row;
    }
}

@media (max-width: 767px) {
    .dashboard {
        flex-direction: column;
    }

    .main-content {
        padding: 0;
    }
}

.sidebar-open {
    overflow: hidden;
}

.header {
    height: 60px;
    background-color: #3B1E54;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header .toggle-button {
    font-size: 1.2rem;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
}

.sidebar {
    width: 250px;
    color: #fff;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    transition: transform 0.3s ease;
}

.sidebar.mobile {
    width: 200px;
    transform: translateX(0);
}

.sidebar .close-button {
    display: none;
}

@media (max-width: 767px) {
    .sidebar {
        transform: translateX(-100%);
    }

    .sidebar.mobile {
        transform: translateX(0);
    }

    .sidebar .close-button {
        display: block;
        color: #fff;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        padding: 1rem;
        text-align: right;
    }
    .min-content {
        margin-left: 3%;
        width: 50%;
        padding: 0;
    }
    .cotent-container {
        width: 165%;
        margin-left: 20%;
    }
}

button {
    padding: 0.5rem 1rem;
    background-color: #3B1E54;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #4c2a6d;
}