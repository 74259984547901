.custom-section {
    position: relative;
}
.entreprise{
    color: #640c64;
    font-family: Sanchez, serif;
}
.payement{
    color: #640c64;
    font-family: Sanchez, serif;
}
.mode-payaiment{
    font-family: Montserrat, serif;
}
.client{
    font-family: Montserrat, serif;
    color: black;
}
.img{
    width: 200px;
}
.devs{
    color: #640c64;
    font-family: Sanchez, serif;
    margin-top: 6%;
}
.top-strip {
    height: 20px;
    background-color: #640c64;
}

.bottom-strip {
    height: 20px;
    background-color: #640c64;
}

.infos{
    margin-left: 10%;
}

.table-header{
    background-color: #640c64;
}
.p-6{
    color: white;
    font-family: Montserrat, serif;
}
.prices{
    color: #640c64;
}
.infos{
    margin-top: 2%;
}
.hr{
    margin-top: 10%;
    border: 1px solid #640c64;
}
@media print {
    .no-pdf {
        display: none;
    }
}
